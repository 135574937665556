<template>
  <ion-page id="characters-page" class="page scrollable bg-transparent">
    <h1 class="title">
      {{ group.name }}
    </h1>
    <div class="w-100">
      <div v-for="(event, sIndex) in nonExpiredEvents(group.events)" :key="sIndex" class="py-1">
        <div class="event">
          <h2 class="title mb-0">
            <router-link :to="getEventPath(event)" class="clickable-item">{{ event.title }}</router-link>
          </h2>
          <section v-if="ongoingTasks[event.id]?.length">
            <ul>
              <li v-for="(task, tIndex) in ongoingTasks[event.id]" :key="`ti${task.id}_${tIndex}`">
                <router-link class="clickable-item-hov" :to="getTaskPath(task)">{{ task.name }}</router-link>
                <ion-badge v-if="hasUserSubmitted(task)" class="completed" color="tertiary" title="Done!"
                  ><i class="ti-check"
                /></ion-badge>
                <br />
                <vue-countdown
                  v-if="task.ends_at"
                  v-slot="{ days, hours, minutes, seconds }"
                  :time="formatFromNowInMilliseconds(task.ends_at)"
                  class="countdown"
                >
                  <span>Ends in </span><span v-if="days">{{ days }}d </span><span v-if="hours">{{ hours }}h </span
                  ><span v-if="!days">{{ minutes }}m </span><span v-if="!days && !hours">{{ seconds }}s</span>
                </vue-countdown>
              </li>
              <li v-for="(task, tIndex) in upcomingTasks[event.id]" :key="`ti${task.id}_${tIndex}`">
                <router-link class="clickable-item-hov gray" :to="getTaskPath(task)">{{ task.name }}</router-link>
                <ion-badge v-if="hasUserSubmitted(task)" class="completed" color="tertiary" title="Done!"
                  ><i class="ti-check"
                /></ion-badge>
                <br />
                <vue-countdown
                  v-if="task.ends_at"
                  v-slot="{ days, hours, minutes, seconds }"
                  :time="formatFromNowInMilliseconds(task.ends_at)"
                  class="countdown gray"
                >
                  <span>Starts in </span><span v-if="days">{{ days }}d </span><span v-if="hours">{{ hours }}h </span
                  ><span v-if="!days">{{ minutes }}m </span><span v-if="!days && !hours">{{ seconds }}s</span>
                </vue-countdown>
              </li>
            </ul>
          </section>
        </div>
      </div>
      <div v-if="!group.events || !group.events.length">No ongoing events</div>
    </div>
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { toast } from '@/shared/native';
import { SiteEvent, SiteEventTask, SiteEventGroup } from '@/shared/types/static-types';
import { formatTimeAgo, dateHasPassed, formatFromNowInMilliseconds } from '@/shared/utils/dateTime';
import { getEventGroup } from '@/shared/actions/events';


@Options({
  name: 'EventGroupPage',
})
export default class EventGroupPage extends Vue {
  public group: SiteEventGroup = { id: '', name: '', events: [], slug: '', tasks: {} };
  public ongoingTasks: any = {};
  public upcomingTasks: any = {};
  formatTimeAgo = formatTimeAgo;
  dateHasPassed = dateHasPassed;
  formatFromNowInMilliseconds = formatFromNowInMilliseconds;

  public async fetchGroup() {
    try {
      const router = useRouter();
      const id = router.currentRoute.value.params.id;
      this.group = await getEventGroup(id);
      this.group?.events.forEach((event: SiteEvent) => {
        this.ongoingTasks[event.id] = this.group?.tasks![event.id].filter(
          (task: SiteEventTask) =>
            (!task.starts_at || dateHasPassed(task.starts_at)) && (!task.ends_at || !dateHasPassed(task.ends_at))
        );
        this.upcomingTasks[event.id] = this.group?.tasks![event.id].filter(
          (task: SiteEventTask) => task.starts_at && !dateHasPassed(task.starts_at)
        );
      });
    } catch (e) {
      await toast.show('Could not load ongoing events. Please try again later.', 'nonative', 'danger');
    }
  }

  public getEventPath(event: SiteEvent) {
    return `/events/event/${event.slug}`;
  }

  public getTaskPath(task: SiteEventTask) {
    if (task.task_type === 'poll') return `/events/poll/${task.id}`;
    return `/events/task/${task.id}`;
  }

  public hasUserSubmitted(task: SiteEventTask) {
    return task.user_submission_count && task.user_submission_count > 0;
  }

  public nonExpiredEvents(events: SiteEvent[]) {
    return events.filter((event) => !dateHasPassed(event.ends_at));
  }

  public async created() {
    const document = useDocument();
    document.value?.getElementById('app-nav-bar')?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    this.fetchGroup();
  }
}
</script>
<style lang="sass" scoped>
h2
  font-size: 24px
h3
  font-size: 20px
  margin-top: 0.5rem
ul
  list-style-type: none
  padding: 0
  margin: 0.5rem 0 0 0
li
  margin-top: 0.4rem
.clickable-item:hover
  opacity: 0.7
.event-container
  padding: 1rem
  border-radius: 10px
  padding-bottom: 4px
.infinite-home
  --background: transparent
  height: calc(100% - 58px - 40px)
  overflow: auto
  &::-webkit-scrollbar
    display: none
.title
  font-weight: bold
.desc-box
  border-radius: 10px
  background-color: #CCC
  color: black
  font-size: 12px
  .link-color
    color: #e3a7ff
.event-preview
  width: 100%
  img
    object-fit: contain
    border-radius: 10px
.countdown
  span
    color: gray !important
    font-size: 14px
.completed
  position: relative
  top: 2px
  font-size: 8px
  margin-left: 8px
.clickable-item-hov
  background: none
  opacity: 0.8
  &:hover
    opacity: 1
.gray
  color: gray
</style>
